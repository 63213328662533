import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaEyeSlash } from "@react-icons/all-files/fa/FaEyeSlash";
import axios from 'axios';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Layouts/DefaultHeader';
import Footer from '../Layouts/Footer';
import ValidationSchema from '../Schemas/ResetPasswordValidationSchema';

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState(false);
    const [resetToken, setResetToken] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const toastRef = useRef(null);

    const RESET_PASSWORD_API = `${BASE_URL}/api/user/change-password?token=${resetToken}&email=${email}`;

    const showToast = (severity, summary, detail) => {
      toastRef.current.show({ severity, summary, detail });
    };

    useEffect (() => {
      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get('token');
      const email = searchParams.get('email');

      if (!token || !email) {
        setIsAuthenticated(false);
        navigate('/');
      } else {
        setResetToken(token);
        setEmail(email);
        setIsAuthenticated(true);
      }
    }, []);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const onSubmit = (values, actions) => {
      actions.resetForm();
    };
      
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        } = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: ValidationSchema,
        onSubmit,
    });

    const handleResetPassword = async () => {
      try {
        const response = await axios.post(RESET_PASSWORD_API, {resetToken, email, password: values.password});
        if (response.data.statusCode === 200 || response.data.statusCode === 201) {
          showToast('success', 'Success', response.data.message);
        } else if (response.data.statusCode === 500) {
          navigate('/500'); 
        } else {
          showToast('error', 'Error', response.data.message);
        }
      } catch (error) {
        navigate('/network-error');
      }
    };

  return (
    <div>
      <Header />
      <div className="content">
        <div className='fp-container mx-auto vh-100'>
          <Card>
            <Card.Header>Reset Password</Card.Header>
            <Card.Body>
                <Card.Title></Card.Title>
                <Card.Text>Please enter a new password.</Card.Text>
                  <form action='' onSubmit={handleSubmit}>
                      <div className='fp-form mx-auto mt-5 mb-4'>
                        <div className='mb-2'>
                          <label htmlFor='new-password'>Password</label>
                          <div className="input-with-icon">
                            <input
                            id='new-password'
                            type= {showPassword ? 'text' : 'password'}
                            name='password'
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`form-control ${errors.password && touched.password ? 'input-error' : ''}`}
                            />
                            <span className="input-icon" onClick={handleTogglePassword}>
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                            </span>
                          </div>
                          {errors.password && touched.password && <small className="text-danger">{errors.password}</small>}
                        </div>
                        <div className='mb-2'>
                          <label htmlFor='confirm-password'>Confirm Password</label>
                          <div className="input-with-icon">
                            <input
                            id='confirm-password'
                            type= {showConfirmPassword ? 'text' : 'password'}
                            name='confirmPassword'
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`form-control ${errors.confirmPassword && touched.confirmPassword ? 'input-error' : ''}`}
                            />
                            <span className="input-icon" onClick={handleToggleConfirmPassword}>
                            {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                            </span>
                        </div>
                        {errors.confirmPassword && touched.confirmPassword && <small className="text-danger">{errors.confirmPassword}</small>}
                        </div>
                      </div>
                  </form>
                
            </Card.Body>
            <Card.Footer>
              <div className='text-end'>
                <button type='submit' className='btn btn-primary' onClick={handleResetPassword}>Submit</button>
              </div>
            </Card.Footer>
          </Card>
          <div className='text-end mt-3'>
            <Link to='/'>
              <button type='submit' className='btn btn-primary'>Proceed to Login</button>
            </Link>
          </div>
          <Toast ref={toastRef} />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ResetPassword;
