import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaEyeSlash } from "@react-icons/all-files/fa/FaEyeSlash";
import axios from 'axios';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import ValidationSchema from '../Schemas/RegisterValidationSchema';
import '../../css/App.css';
import '../../css/style.css';

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

const RegisterModal = ({ show, onHide, fetchUsers }) => {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const toastRef = useRef(null);

  const GET_OFFICES_API = `${BASE_URL}/api/user/get-all-office-codes`;
  const REGISTER_API = `${BASE_URL}/api/user/register`;

  const showToast = (severity, summary, detail) => {
    toastRef.current.show({ severity, summary, detail });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect (() => {
    const accessToken = Cookies.get('accessToken');
    const fetchOffice = async () => {
      try {
        const response = await axios.get(
          GET_OFFICES_API, 
          {
            headers: {
              'Content-Type': 'application/json',
              'authorization': `${accessToken}`,
            },
            withCredentials: true,
          });
        setOptions(response.data.response);
      } catch (error) {
        showToast('error', 'Error', error.message);
      }
    };
    fetchOffice();
  }, []);

  const [formState, setFormState] = useState({
    data: {},
  });

  const onSubmit = (values, actions) => {
    actions.resetForm();
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      password: '',
      confirmPassword: '',
      role: '',
      office_code: '',
    },
    validationSchema: ValidationSchema,
    onSubmit,
  });

  const handleRegistration = async () => {
    try {
      setLoading(true);
      await ValidationSchema.validate(values);
      const response = await axios.post(REGISTER_API, formState.data, {withCredentials: true});
      if (response.data.statusCode === 200 || response.data.statusCode === 201) {
        showToast('success', 'Success', response.data.message);
        setTimeout(() => {
          onHide(true);
        }, 1000);
        fetchUsers();
      } else if (response.data.statusCode === 500) {
        navigate('/500');
      } else {
        showToast('error', 'Error', response.data.message);
      }
    } catch (error) {
      navigate('/network-error');
    } finally {
      setLoading(false);
    }
  };

  const handleInput = (e) => {
    if (e && e.target && e.target.name) {
      setFormState({
        ...formState,
        data: {
          ...formState.data,
          [e.target.name]: e.target.value,
        },
      });
      handleChange(e);
    }
    selectOffice(e);
    selectRole(e);
  };

  const selectRole = (e) => {
    if (e && e.name && e.value) {
      setFieldValue('role', e.value);
      setFormState({
        ...formState,
        data: {
          ...formState.data,
          [e.name]: e.value,
        },
      });
    } 
  };

  const selectOffice = (e) => {
    if (e && e.value) {
      setFieldValue('office_code', e.value);
      setFormState({
        ...formState,
        data: {
          ...formState.data,
          office_code: e.value,
        },
      });
    } 
  };

  const accountType = [
    { name:'role', value: 'admin', label: 'Admin' },
    { name:'role', value: 'crossmatcher', label: 'Crossmatcher' }
  ];

  return (
    <Modal {...{ show, onHide }} size="md" centered>
      <Modal.Header closeButton>
      <strong>Add New User</strong>
      </Modal.Header>
      <Modal.Body>
        <div className='container px-5 py-4 rounded bg-white'>
          <form action='' onSubmit={handleSubmit}>
            <div className='form-header'>
              <Row className="mb-3">
                <Col md="5">
                  <img
                    src="/img/DA-LOGO-1024x1024.png"
                    alt="Logo"
                    style={{ width: '150px', height: '150px' }}
                  />
                </Col>
                <Col md="7">
                  <br />
                  <h6 className="text-black">Registry System for Basic Sectors in Agriculture</h6>
                  <h3 className="text-black">RSBSA Crossmatch</h3>
                </Col>
              </Row>
            </div>
            <Row className='mb-1'>
              <Col md="6">
                <div className='mb-2'>
                  <label htmlFor='first-name'>First Name <span style={{ color: 'red' }}>*</span> </label>
                  <input
                    id="first-name"
                    type='text'
                    name='firstName'
                    placeholder='Enter First Name'
                    value={values.firstName}
                    onChange={handleInput}
                    onBlur={handleBlur}
                    className={`form-control ${errors.firstName && touched.firstName ? 'input-error' : ''}`}
                  />
                  {errors.firstName && touched.firstName && <small className="text-danger">{errors.firstName}</small>}
                </div>
              </Col>
              <Col md="6">
                <div className='mb-2'>
                  <label htmlFor='last-name'>Last Name <span style={{ color: 'red' }}>*</span> </label>
                  <input
                    id="last-name"
                    type='text'
                    name='lastName'
                    placeholder='Enter Last Name'
                    value={values.lastName}
                    onChange={handleInput}
                    onBlur={handleBlur}
                    className={`form-control ${errors.lastName && touched.lastName ? 'input-error' : ''}`}
                  />
                  {errors.lastName && touched.lastName && <small className="text-danger">{errors.lastName}</small>}
                </div>
              </Col>
            </Row>
            <div className='mb-2'>
              <label htmlFor='user-name'>Username <span style={{ color: 'red' }}>*</span> </label>
              <input
                id="user-name"
                type='text'
                name='userName'
                placeholder='Enter Username'
                value={values.userName}
                onChange={handleInput}
                onBlur={handleBlur}
                className={`form-control ${errors.userName && touched.userName ? 'input-error' : ''}`}
              />
              {errors.userName && touched.userName && <small className="text-danger">{errors.userName}</small>}
            </div>
            <div className='mb-2'>
              <label htmlFor='e-mail'>Email <span style={{ color: 'red' }}>*</span> </label>
              <input
                id="e-mail"
                type='email'
                name='email'
                placeholder='Enter Email'
                value={values.email}
                onChange={handleInput}
                onBlur={handleBlur}
                className={`form-control ${errors.email && touched.email ? 'input-error' : ''}`}
              />
              {errors.email && touched.email && <small className="text-danger">{errors.email}</small>}
            </div>
            <div className='mb-2'>
              <label htmlFor='pass-word'>Password <span style={{ color: 'red' }}>*</span> </label>
              <div className="input-with-icon">
                <input
                  id='pass-word'
                  type= {showPassword ? 'text' : 'password'}
                  name='password'
                  placeholder='Enter Password'
                  value={values.password}
                  onChange={handleInput}
                  onBlur={handleBlur}
                  className={`form-control ${errors.password && touched.password ? 'input-error' : ''}`}
                />
                <span className="input-icon" onClick={handleTogglePassword}>
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
              {errors.password && touched.password && <small className="text-danger">{errors.password}</small>}
            </div>
            <div className='mb-2'>
              <label htmlFor='confirm-password'>Confirm Password <span style={{ color: 'red' }}>*</span> </label>
              <div className="input-with-icon">
                <input
                  id='confirm-password'
                  type= {showConfirmPassword ? 'text' : 'password'}
                  name='confirmPassword'
                  placeholder='Enter Password'
                  value={values.confirmPassword}
                  onChange={handleInput}
                  onBlur={handleBlur}
                  className={`form-control ${errors.confirmPassword && touched.confirmPassword ? 'input-error' : ''}`}
                />
                <span className="input-icon" onClick={handleToggleConfirmPassword}>
                  {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
                {errors.confirmPassword && touched.confirmPassword && <small className="text-danger">{errors.confirmPassword}</small>}
            </div>
            <div className='mb-2'>
              <label htmlFor='role'>Role <span style={{ color: 'red' }}>*</span> </label>
              <Select
                id='accountType'
                name='role'
                placeholder='Select Account Type..'
                options={accountType} 
                value={accountType.find(option => option.value === values.role)}
                onChange={handleInput}
                className={errors.role && touched.role ? 'input-error' : ''}
              />
              {errors.role && touched.role && <small className="text-danger">{errors.role}</small>}
            </div>
            <div className='mb-3'>
              <label htmlFor='role'>Office <span style={{ color: 'red' }}>*</span> </label>
              <Select
                id='office-code'
                name='office_code'
                placeholder='Select Office..'
                options={[...options.map((option)=>({label: option, key: option, value: option })),
                ]} 
                value={options.find(option => option.value === values.office_code)}
                onChange={handleInput}
                className={errors.office_code && touched.office_code ? 'input-error' : ''}
              />
              {errors.office_code && touched.office_code && <small className="text-danger">{errors.office_code}</small>}
            </div>
            <div className='d-grid'>
              <button type='submit' className='btn btn-primary' onClick={handleRegistration} disabled={isSubmitting}>
                {loading ? <i className="pi pi-spin pi-spinner" style={{ fontSize: '1rem' }}></i> : 'Register'}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Toast ref={toastRef} />
    </Modal>
  );
};

export default RegisterModal;
