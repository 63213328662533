import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { FaRegEdit } from "react-icons/fa";
import { FaSearch } from 'react-icons/fa';
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../Components/DeleteConfirmationModal';
import EditModal from '../Components/EditModal';
import RegisterModal from '../Components/RegisterModal';
import SessionExpiredModal from '../Components/SessionExpiredModal';
import { useUserContext } from '../Components/UserContext';
import Header from '../Layouts/Header';

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

const ManageUsers = () => { 
  const navigate = useNavigate();
  const { handleLogout, refreshToken, sessionModalShow, setSessionModalShow } = useUserContext();
  const [registerModalShow, setRegisterModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [deleteUserEmail, setDeleteUserEmail] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const toastRef = useRef(null);
  const accessToken = Cookies.get('accessToken');
  const sessionToken = Cookies.get('sessionToken');
  const decodedAccessToken = jwtDecode(accessToken);

  const DELETE_API = `${BASE_URL}/api/user/delete-user?email=${decodedAccessToken.email}&userEmail=${deleteUserEmail}`;
  const GET_USERS_API = `${BASE_URL}/api/user/get-all-user?email=${decodedAccessToken.email}`;
  

  const showToast = (severity, summary, detail) => {
    if (toastRef.current) {
      toastRef.current.show({ severity, summary, detail });
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        GET_USERS_API, 
        {
          headers: {
            'Content-Type': 'application/json',
            'authorization': `${accessToken}`,
          },
          withCredentials: true,
        }
      );
      setUsers(response.data.response);
    } catch (error) {
      showToast('error', 'Error', error.message);
    }
  }
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!accessToken) { 
          navigate('/', { replace: true });
        } else {
          const response = await axios.get(
            GET_USERS_API, 
            {
              headers: {
                'Content-Type': 'application/json',
                'authorization': `${accessToken}`,
              },
              withCredentials: true,
            }
          );
          setUsers(response.data.response);
          if (response.data.name === "TokenExpiredError") {
            refreshToken();
          }
        }
      } catch (error) {
        showToast('error', 'Error', error.message);
      }
    };
    fetchData();
    fetchUsers();
  }, [navigate, accessToken, decodedAccessToken.email]);

  const onGlobalFilterChange = (event) => {
    setGlobalFilter(event.target.value);
  };
  
  const filteredData = users ? users.filter((rowData) => {
    return Object.values(rowData).some((value) =>
      String(value).toLowerCase().startsWith(globalFilter.toLowerCase())
    );
  }) : [];  

  const toggleRegisterModal = () => {
    setRegisterModalShow(!registerModalShow);
  };

  const toggleEditModal = () => {
    setEditModalShow(!editModalShow);
  };

  const toggleConfirmationModal = () => {
    setShowConfirmation(!showConfirmation);
  };

  const handleDeleteUser = async () => {
    try {
      const response = await axios.delete(
        DELETE_API,
        {
          headers: {
            'Content-Type': 'application/json',
            'authorization': `${accessToken}`,
          },
          withCredentials: true,
        });
      if (response.data.statusCode === 200) {
        showToast('success', 'Success', response.data.message);
        fetchUsers();
      } else if (response.data.statusCode === 500) {
        navigate('/500');
      } else {
        showToast('error', 'Error', response.data.message);
      }
    } catch (error) {
      navigate('/network-error');
    } finally {
      toggleConfirmationModal();
    }
  };

  return (
    <div>
      <Header handleLogout={handleLogout} />
      <div className="content">
        <div className="vh-100">
          <div id='home-container' className='home-container mx-2 p-5 pt-5 rounded bg-white'>
            <div id='home-row' className="row mb-3">
              <div id='row-img' className="col-md-2 offset-3">
                <img
                  src="/img/DA-LOGO-1024x1024.png"
                  alt="Logo"
                  style={{ width: '150px', height: '150px' }}
                />
              </div>
              <div id='row-title' className="col-md-3">
                <br />
                <h5 className="text-black">Registry System for Basic Sectors in Agriculture</h5>
                <h1 className="text-black">RSBSA Crossmatch</h1>
              </div>
            </div>
            <div className='d-flex justify-content-between border-top'>
              <p className='text-start ms-3 mt-3'>
                <button className='btn btn-primary' onClick={() => {toggleRegisterModal();}}>Add new user</button>
              </p>
              <p className="text-start ms-3 mt-3">
                <input
                  type="text"
                  value={globalFilter}
                  onChange={onGlobalFilterChange}
                  placeholder="Search..."
                  style={{
                    padding: '12px 40px 12px 15px',
                    fontSize: '16px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    marginRight: '10px',
                    marginBottom: '10px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    boxSizing: 'border-box',
                    outline: 'none',
                    transition: 'border-color 0.2s',
                  }}
                  onFocus={(e) => (e.target.style.borderColor = '#89CFF0')}
                  onBlur={(e) => (e.target.style.borderColor = '#ccc')}
                />
                <span style={{ position: 'relative', left: '-40px', bottom: '3px' }}>
                  <FaSearch color="#4682B4" size={18} />
                </span>
              </p>
            </div>
            {users && users.length > 0 ? (
              <DataTable value={filteredData} className="p-datatable-striped" paginator rows={10} 
                rowsPerPageOptions={[5, 10, 25, 50]} size={'small'} removableSort>
                <Column field="firstName" header="Firstname" sortable />
                <Column field="lastName" header="Lastname" sortable />
                <Column field="userName" header="Username" sortable />
                <Column field="email" header="Email" sortable />
                <Column field="office_code" header="Office" sortable />
                <Column field="role" header="Role" sortable />
                <Column body={(rowData) => (
                  <div>
                    <FaRegEdit style={{ cursor: 'pointer', marginRight: '8px', color: 'blue' }} onClick={() => { toggleEditModal(); setSelectedUser(rowData) }} />
                    <RiDeleteBin6Line style={{ cursor: 'pointer', color: 'red' }} onClick={() => { setDeleteUserEmail(rowData.email); toggleConfirmationModal(); }} />
                  </div>
                )} header="Actions" />
              </DataTable>
            ) : (
              <div>No users available. </div>
            )}
          </div>
          <ConfirmationModal
            show={showConfirmation}
            onHide={() => setShowConfirmation(false)}
            onConfirm={handleDeleteUser}
            message="Are you sure you want to delete this user?"
          />
          <SessionExpiredModal show={sessionModalShow} onHide={() => setSessionModalShow(false)} handleLogout={handleLogout} />
          <RegisterModal show={registerModalShow} onHide={() => setRegisterModalShow(false)} fetchUsers={fetchUsers} />
          <EditModal show={editModalShow} onHide={() => setEditModalShow(false)} fetchUsers={fetchUsers} selectedUser={selectedUser} />
          <Toast ref={toastRef} />
        </div>
      </div>
    </div>
  );
};

export default ManageUsers;