import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { BsCloudUpload } from "react-icons/bs";
import { MdOutlineUploadFile } from "react-icons/md";
import { VscError } from "react-icons/vsc";
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../Components/UserContext';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Header from '../Layouts/Header';
import '../../css/App.css';
import '../../css/style.css';

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

const UpdateDatalist = () => {
  const navigate = useNavigate()
  const { updatedBy, updatedOn } = useUserContext();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loadingGif, setLoadingGif] = useState('');
  const toastRef = useRef(null);
  const isComponentMounted = useRef(true);
  const accessToken = Cookies.get('accessToken');
  const decodedAccessToken = jwtDecode(accessToken);

  const UPDATE_DATALIST_API = `${BASE_URL}/api/user/update-datalist?email=${decodedAccessToken.email}`;

  const showToast = (severity, summary, detail) => {
    toastRef.current.show({ severity, summary, detail });
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
        setUploadedFile(file);
        setError(null);
      } else {
        setError('Invalid file format: Please upload a .CSV file.');
      }
    } else {
        setError('Error: Please upload only one file at a time.');
    }
  }, []);

  const clearUpload = (e) => {
    e.stopPropagation();
    setUploadedFile(null);
    setError(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv',
    maxFiles: 1,
    disabled: uploading,
  });

  const handleSubmit = async () => {
    try {
      setUploading(true);
      setUploadProgress(0);
      const formData = new FormData();
      formData.append('importFile', uploadedFile);
      const response = await axios(UPDATE_DATALIST_API, {
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type' : 'multipart/form-data',
          'authorization': `${accessToken}`,
        },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : (progressEvent.target && (
                progressEvent.target.getResponseHeader('content-length') ||
                progressEvent.target.getResponseHeader('x-decompressed-content-length')
              ));
          if (totalLength !== null && totalLength !== undefined) {
            const progress = Math.round((progressEvent.loaded * 100) / totalLength);
            setUploadProgress(progress);
          }
        },        
      }, {withCredentials: true})
      if (response.data.statusCode === 200) {
        showToast('success', 'Success', response.data.message);
      } else if (response.data.statusCode === 500) {
        navigate('/500');
      } else if (response.data.statusCode === 424) {
        showToast('error', 'Error', response.data.message);
      } else {
        showToast('error', 'Error', response.data.message);
      }
    } catch (error) {
      navigate('/network-error');
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    if (!accessToken) {
      navigate('/', { replace: true });
    }
    return () => {
      isComponentMounted.current = false;
    };
  }, [navigate, accessToken]);  

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (uploading) {
        event.returnValue = "Upload in progress. Are you sure you want to leave?";
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploading]);
  
  useEffect(() => {
    const gifOptions = ['/img/loading.gif', '/img/loading2.gif', '/img/loading3.gif',
    '/img/loading4.gif', '/img/loading5.gif', '/img/loading6.gif','/img/loading7.gif', 
    '/img/loading2.gif', '/img/loading3.gif', '/img/loading8.gif', '/img/loading9.gif', 
    '/img/loading10.gif'];
    const randomGif = gifOptions[Math.floor(Math.random() * gifOptions.length)];
    setLoadingGif(randomGif);

    const timeoutId = setTimeout(() => {
      setUploading(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div>
      <Header updatedOn={updatedOn} />
      <div className="content">
        <div className="vh-100">
          <div id='home-container' className='home-container mx-2 p-5 rounded bg-white'>
            <div id='home-row' className="row mb-3 pb-3 border-bottom">
              <div id='row-img' className="col-md-2 offset-3">
                <img
                  src="/img/DA-LOGO-1024x1024.png"
                  alt="Logo"
                  style={{ width: '150px', height: '150px' }}
                />
              </div>
              <div id='row-title' className="col-md-3">
                <br />
                <h5 className="text-black">Registry System for Basic Sectors in Agriculture</h5>
                <h1 className="text-black">RSBSA Crossmatch</h1>
              </div>
            </div>
            <Row className='g-2 text-start mb-2 mx'>
              <Col md="auto"><Card className='update_db-card' title={'Last Updated On:'}> {`${updatedOn}`} </Card></Col>
              <Col md="auto"><Card className='update_db-card' title={'Last Updated By:'}> {`${updatedBy}`} </Card></Col>
            </Row>
            <div>
              {uploading && (
                <div className="my-4">
                  <ProgressBar
                    mode="indeterminate"
                    value={uploadProgress}
                    style={{ height: '20px' }}
                    showValue
                  />
                </div>
              )}
            </div>
            <div
              {...getRootProps()}
              className={`dropzone ${
                uploadedFile
                  ? 'dropzone-uploaded'
                  : error
                  ? 'dropzone-error'
                  : isDragActive
                  ? 'dropzone-active'
                  : 'dropzone-base'
              }`}>
              <input {...getInputProps()} />
              <div>
                {uploading ? (
                  <div className="container">
                    <div className="loading-animation">
                    <img src={loadingGif} />
                    </div>
                    <div className="text-success">
                      Uploading data...
                    </div>
                  </div>
                ) : (
                  <>
                    {uploadedFile ? (
                      <div className="text-success">
                        <MdOutlineUploadFile size={100} className='mb-3' />
                        <br />
                        <span>Uploaded File: {uploadedFile.name}</span>
                        <br />
                        <button className="btn btn-primary mt-3" onClick={(e) => clearUpload(e)}>
                          Clear
                        </button>
                      </div>
                    ) : (
                      error ? (
                        <span className="text-danger">
                          <VscError size={100} className='mb-3' />
                          <br />
                          {error}
                        </span>
                      ) : (
                        <div style={{ color: isDragActive ? '#8BC6FC' : 'gray' }}>
                          <BsCloudUpload size={100} className='mb-3' />
                          <br />
                          {isDragActive ? 'Drop the file here!' : 'Drag and drop a CSV file, or click here to browse files.'}
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            </div>
            <div className='text-end mt-2'>
              {uploadedFile && (
                <div>
                  <button 
                  type='submit' 
                  className='btn btn-primary' 
                  onClick={handleSubmit} 
                  disabled={uploading}>
                    Upload Datalist
                  </button>
                  {submitError && (
                    <div className="text-danger mt-2">
                      <VscError size={20} className='mb-1' />
                      <br />
                      {submitError}
                    </div>
                  )}
                </div>
              )}
            </div>
          <Toast ref={toastRef} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateDatalist;
