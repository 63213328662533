import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import React, { createContext, useContext, useState, useEffect, useRef} from 'react';

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';
const TOKEN_REFRESH_API = `${BASE_URL}/api/user/refresh-token`;

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [sessionModalShow, setSessionModalShow] = useState(false);
  const [updatedOn, setUpdatedOn] = useState('')
  const [updatedBy, setUpdatedBy] = useState('')
  const [error, setError] = useState(null);
  const toastRef = useRef(null);
  const accessToken = Cookies.get('accessToken');
  const sessionToken = Cookies.get('sessionToken');

  const LAST_UPDATED_API = `${BASE_URL}/api/user/get-last-update`;

  const showToast = (severity, summary, detail) => {
    toastRef.current.show({ severity, summary, detail });
  };

  const toggleSessionModal = () => {
    setSessionModalShow(!sessionModalShow);
  };
  
  const refreshToken = async () => {
    try {
      const refreshResponse = await axios.post(`${TOKEN_REFRESH_API}?sessionToken=${sessionToken}`, {withCredentials: true});
      const newAccessToken = refreshResponse.data.response;
      window.location.reload(true);
      Cookies.set('accessToken', newAccessToken);
    } catch (error) {
      toggleSessionModal();
    }
  }

  const fetchLastUpdate = async () => {
 
    try {
      const response = await axios.get(
        LAST_UPDATED_API,
        {
          headers: {
            'Content-Type': 'application/json',
            'authorization': `${accessToken}`,
          },
          withCredentials: true,
        }
      );
      
      if (response.data.statusCode === 200) {
        const dateUpdated = new Date(response.data.response[0].updatedAt).toLocaleString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
        setUpdatedBy(response.data.response[0].updatedBy)
        setUpdatedOn(dateUpdated)
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      // showToast('error', 'Error', error.message);
      // window.location.replace('/500')
  
      console.log('backend disconnected')
    }
  };

  useEffect (() => {
    fetchLastUpdate();
  }, []);

  return (
    <UserContext.Provider value={{ updatedOn, updatedBy, refreshToken, sessionModalShow, setSessionModalShow }}>
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = () => {
    return useContext(UserContext);
};

export { UserProvider, useUserContext };
