import axios from 'axios';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import React, { useRef, useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDropzone } from 'react-dropzone';
import { BsCloudUpload, BsInfoCircle } from "react-icons/bs";
import { MdOutlineUploadFile } from "react-icons/md";
import { VscError } from "react-icons/vsc";
import { useNavigate } from 'react-router-dom';
import MatchTable from '../Components/MatchTable';
import { useUserContext } from '../Components/UserContext';
import Header from '../Layouts/Header';
import '../../css/App.css';
import '../../css/style.css';

import io from 'socket.io-client';

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';
const SOCKET_URL = process.env.REACT_APP_SOCKET;

const socket = io(SOCKET_URL,{cors:{ origin:'*'}});
let email = null;
const Home = () => {
  const accessToken = Cookies.get('accessToken');
  const decodedAccessToken = jwtDecode(accessToken);
  const navigate = useNavigate()
  const { updatedOn } = useUserContext();
  const [withMatchData, setWithMatchData] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loadingGif, setLoadingGif] = useState('');
  const toastRef = useRef(null);
  const isComponentMounted = useRef(true);
  const [completedRow, setCompletedRow] = useState(0);
  const MATCH_API = `${BASE_URL}/api/user/match-data?email=${decodedAccessToken.email}`;
  const TOKEN_REFRESH_API = `${BASE_URL}/api/user/refresh-token`;
  email = decodedAccessToken.email;
  const showToast = (severity, summary, detail) => {
    toastRef.current.show({ severity, summary, detail });
  };

  const downloadTemplate = () => {
    const templatePath = '/template.csv';
    const link = document.createElement('a');
    link.href = templatePath;
    link.download = 'template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
        setUploadedFile(file);
        setError(null);
      } else {
        setError('Invalid file format: Please upload a .CSV file.');
      }
    } else {
        setError('Error: Please upload only one file at a time.');
    }
  }, []);

  const clearUpload = (e) => {
    e.stopPropagation();
    setUploadedFile(null);
    setError(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv',
    maxFiles: 1,
    disabled: uploading,
  });

  const handleSubmit = async () => {
    try {
      setUploading(true);
      setUploadProgress(0);
      const formData = new FormData();
      formData.append('importFile', uploadedFile);
      const response = await axios(MATCH_API, {
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type' : 'multipart/form-data',
          'authorization': `${accessToken}`,
        },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : (progressEvent.target && (
                progressEvent.target.getResponseHeader('content-length') ||
                progressEvent.target.getResponseHeader('x-decompressed-content-length')
              ));
          // if (totalLength !== null && totalLength !== undefined) {
          //   const progress = Math.round((progressEvent.loaded * 100) / totalLength);
          //   setUploadProgress(progress);
          // }
        },
      }, {withCredentials: true})
      if (response.data.statusCode === 200) {
        // setProgressbar(0);
        // const withMatchDataArray = response.data.response;
        // console.log(withMatchDataArray)
        // setWithMatchData(withMatchDataArray);
        // setSubmitError('');
        // setShowTable(!showTable);
      } else if (response.data.statusCode === 500) {
        navigate('/500');
      } else if (response.data.statusCode === 424) {
        showToast('error', 'Error', response.data.message);
      } else {
        setSubmitError(response.data.message);
        setShowTable(showTable);
      }
    } catch (error) {
      navigate('/network-error');
      setShowTable(showTable);
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    if (!accessToken) {
      navigate('/', { replace: true });
    }
    return () => {
      isComponentMounted.current = false;
    };
  }, [navigate, updatedOn]); 

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (uploading) {
        event.returnValue = "Upload in progress. Are you sure you want to leaves?";
      }
    };
   
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploading]);
  
  useEffect(() => {
    const gifOptions = ['/img/loading.gif', '/img/loading2.gif', '/img/loading3.gif',
    '/img/loading4.gif', '/img/loading5.gif', '/img/loading6.gif','/img/loading7.gif', 
    '/img/loading2.gif', '/img/loading3.gif', '/img/loading8.gif', '/img/loading9.gif', 
    '/img/loading10.gif'];
    const randomGif = gifOptions[Math.floor(Math.random() * gifOptions.length)];
    setLoadingGif(randomGif);

    const timeoutId = setTimeout(() => {
      setUploading(false);
    }, 1);

    return () => clearTimeout(timeoutId);
  }, []);

  socket.on('percentage', (data) => {

    if(email == data.email){
      setUploadProgress(data.processPercentage);
      if(data.processPercentage == 0){
        setShowTable();
      }
      {data.processPercentage != 100?setUploading(true):setUploading(false);};
      console.log('percentage: ',data.processPercentage, data.email)
    }
  
  });
  socket.on('rowsCompleted', (data) => {
    console.log("rowsCompleted")
    console.log(data.processRow)
    if(email== data.email){
      setCompletedRow(data.processRow)
    }
  });
  socket.on('matchData', (data) => {
    console.log(data.email)
    if(email == data.email){
      setUploading(false);
      setUploadProgress(0);
      setCompletedRow(0);
      setWithMatchData(data.matchData)
      setShowTable(!showTable);
      setSubmitError('');
      setUploadedFile(null);
      setError(null);
    }
  });
  return (
    <div>
      <Header />
      <div className="content">
        {showTable ? (
          <MatchTable withMatchData={withMatchData} setShowTable={setShowTable} />
        ) : (
        <div className="vh-100">
          <div id='home-container' className='home-container mx-2 p-5 rounded bg-white'>
                <h6>RSBSA Updated data on: {`${updatedOn}`}</h6>
            <div>
              <p className='mb-2'>Please make sure to check and follow the correct column names, if applicable. Only the columns in red are required, order and format does NOT matter. Sample provided below: Or you can download the template here: 
                <a className='mb-2' onClick={downloadTemplate} size="small" raised style={{cursor:'pointer'}}>
                  Download Template &nbsp;
                  <i className="pi pi-download"></i>
                  </a>
                </p>
              <div className='pb-2 border-bottom'>
              </div>
              <div className="card mb-2">
                <DataTable
                  size={'small'}
                  value={[{sys_generated_rsbsa_no: '03-33-33-333-333333',
                  assigned_form_control_no: '03-33-33-333-333333',
                  lastname: 'DELA CRUZ',
                  firstname: 'JUAN',
                  middle_name: 'SANTOS',
                  extname: 'JR',
                  mother_maiden_name: 'JUANA DELA CRUZ',
                  sex: 'MALE',
                  birthday: '12/31/1970',
                  prove_name: 'PAMPANGA',
                  mun_name: 'LAUR',
                  bgy_name: "Nauzon"
                }]}>
                  <Column field="sys_generated_rsbsa_no" header="sys_generated_rsbsa_no"></Column>
                  <Column field="assigned_form_control_no" header="assigned_form_control_no"></Column>
                  <Column field="lastname" header="lastname" style={{color: 'red'}}></Column>
                  <Column field="firstname" header="firstname" style={{color: 'red'}}></Column>
                  <Column field="middle_name" header="middle_name" style={{color: 'red'}}></Column>
                  <Column field="extname" header="extname"></Column>
                  <Column field="mother_maiden_name" header="mother_maiden_name"></Column>
                  <Column field="sex" header="sex"></Column>
                  <Column field="birthday" header="birthday"></Column>
                  <Column field="mun_name" header="mun_name" style={{color: 'red'}}></Column>
                  <Column field="bgy_name" header="bgy_name"></Column>
                  <Column field="prove_name" header="prove_name"></Column>
                </DataTable>
              </div>
              <Row>
              <Col className='text-end mt-2'>
                {uploadedFile && (
                  <div>
                    <button
                    type='submit'
                    className='btn btn-primary'
                    onClick={handleSubmit}
                    disabled={uploading}>
                      Match Data
                    </button>
                    {submitError && (
                      <div className="text-danger mt-2">
                        <VscError size={20} className='mb-1' />
                        <br />
                        {submitError}
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
            <Toast ref={toastRef} />
            </div>

            <div className='mb-4'>
                {uploading && (
                  <div className="mt-4">
                    <ProgressBar
                      mode="determinate"
                      style={{ height: '20px' }}
                      value={uploadProgress}
                    />
                    <p>Completed rows: {completedRow}</p>
                  </div>
                )}
            </div>
            <div className='row justify-content-md-center'>
              <div
                {...getRootProps()}
                className={`col-md-6 dropzone ${
                  uploadedFile
                    ? 'dropzone-uploaded'
                    : error
                    ? 'dropzone-error'
                    : isDragActive
                    ? 'dropzone-active'
                    : 'dropzone-base'
                }`}
                >
                <input {...getInputProps()} />
                <div>
                  {uploading ? (
                    <div className="container">
                      <div className="loading-animation">
                      <img src={loadingGif} className="img-fluid"/>
                      </div>
                      <div className="text-success">
                        CrossMatching data...
                      </div>
                    </div>
                  ) : (
                    <>
                      {uploadedFile ? (
                        <div className="text-success">
                          <MdOutlineUploadFile size={100} className='mb-3' />
                          <br />
                          <span>Uploaded File: {uploadedFile.name}</span>
                          <br />
                          <button className="btn btn-primary mt-3" onClick={(e) => clearUpload(e)}>
                            Clear
                          </button>
                        </div>
                      ) : (
                        error ? (
                          <span className="text-danger">
                            <VscError size={100} className='mb-3' />
                            <br />
                            {error}
                          </span>
                        ) : (
                          <div style={{ color: isDragActive ? '#8BC6FC' : 'gray' }}>
                            <BsCloudUpload size={100} className='mb-3' />
                            <br />
                            {isDragActive ? 'Drop the file here!' : 'Drag and drop a CSV file, or click here to browse files.'}
                          </div>
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Home;
