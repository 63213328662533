import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/style.css';

const NetworkErrorPage = () => {
    return (
        <div className='error-container'>
            <img src="/img/network.png" alt="Network Error" className='error-icon' />
            <h1 className='error-heading'>Oops! Something Went Wrong</h1>
            <p className='error-message'>
              There was a problem with the network. Please try again later.{' '}
                <Link to="/" className='error-link'>
                    Go back to safety
                </Link>
            </p>
        </div>
    );
};

export default NetworkErrorPage;
